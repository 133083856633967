import axios from "axios";
import store from '@/store';
import { $ls, $ss } from "@/plugins/localstorage";

const quickCreatorBaseUrl =  process.env.VUE_APP_QC_BASE_URL;

export const getTemplates = async (sessionId) => {
    try {
        // Return early if the Localstorage contains template data
        if($ls.get('templateData') && $ls.get('templateData').length) return $ls.get('templateData');

        const response = await axios.get(`${quickCreatorBaseUrl}/templates`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-mono-sid': sessionId
                }
            });
       
        // Set localstorage
        $ls.set('templateData', response.data);
        
        return response.data;
    } catch (error) {
        console.log('GET TEMPLATES ERROR --- ', error);
        if (error.message === 'Network Error') {
            console.error('Network Error:', error);
            $ss.set('loginToken', '');
            return 'There was a network error. Please try again later.';
        } else {
            console.error('GET TEMPLATES ERROR', error);
            return 'An error occurred while retrieving templates.';
        }
    }
};

export const getTemplateContentById = async (sessionId, templateId, templatePreviewUrl) => {
    try {
        // Return early if the indexDB contains template data
        const templateContent = await store.state.largeTemplateData || [];
        if(templateContent && templateContent.length) {
            const found = templateContent.find(template => template.id === templateId);
            if(found) {
                return found.data;
            }
        }
        const response = await axios.post(`${quickCreatorBaseUrl}/template-content`, { templatePreviewUrl },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-mono-sid': sessionId
                }
            }
        );

        templateContent.push({ id: templateId, data: response.data });
        // Set indexDB
        await store.dispatch('setLargeTemplateData', templateContent);
        return response.data;
    } catch (error) {
        console.log('GET TEMPLATES BY ID ERROR --- ', error);
        if (error.message === 'Network Error') {
            console.error('Network Error:', error);
            $ss.set('loginToken', '');
            return 'There was a network error. Please try again later.';
        } else {
            console.error('GET TEMPLATES ERROR', error);
            return 'An error occurred while retrieving templates.';
        }
    }
};