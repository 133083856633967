import tinycolor from 'tinycolor2';

class Colors {
    _colorChange(passedData, oldHue) {
        const data = passedData === '' ? 'rgba(255,255,255,1)' : passedData;
        const alpha = data && data.a;
        let color;

        // hsl is better than hex between conversions
        if (data && data.hsl) {
            color = tinycolor(data.hsl);
        } else if (data && data.hex && data.hex.length > 0) {
            color = tinycolor(data.hex);
        } else {
            color = tinycolor(data);
        }

        if (color && (color._a === undefined || color._a === null)) {
            color.setAlpha(alpha || 1);
        }

        const hsl = color.toHsl();
        const hsv = color.toHsv();

        if (hsl.s === 0) {
            hsv.h = data.h || (data.hsl && data.hsl.h) || oldHue || 0;
            hsl.h = hsv.h;
        }

        // when the hsv.v is less than 0.0164 (base on test)
        // because of possible loss of precision
        // the result of hue and saturation would be miscalculated
        if (hsv.v < 0.0164) {
            hsv.h = data.h || (data.hsv && data.hsv.h) || 0;
            hsv.s = data.s || (data.hsv && data.hsv.s) || 0;
        }

        if (hsl.l < 0.01) {
            hsl.h = data.h || (data.hsl && data.hsl.h) || 0;
            hsl.s = data.s || (data.hsl && data.hsl.s) || 0;
        }

        return {
            hsl,
            hex: color.toHexString().toUpperCase(),
            rgba: color.toRgb(),
            hsv,
            oldHue: data.h || oldHue || hsl.h,
            source: data.source,
            a: data.a || color.getAlpha()
        };
    }

    addCssRulesFromPalette(palette, textsWithGlobalColors) {
        // Store the palette globally so it can be accessed across viewports
        window.palette = palette;

        // Generate CSS rules dynamically from the palette
        let cssRules = '';

        const colorProperties = {
            'bgc': 'background-color',
            'bc': 'border-color', 
            'tc': 'color',
            'btc': 'border-top-color',
            'brc': 'border-right-color',
            'bbc': 'border-bottom-color',
            'blc': 'border-left-color'
        };

        Object.keys(palette).forEach(colorKey => {
            const colorValue = palette[colorKey]?.hex;
            if (colorValue) {
                Object.entries(colorProperties).forEach(([abbr, property]) => {
                    cssRules += `.palette_${abbr}_${colorKey} { ${property}: ${colorValue} !important; }\n`;
                });
            }

            const textColorValue = palette[colorKey]?.text;
            if (textColorValue) {
                const splitKey = colorKey.split('_');
                const matchEditorColorKey = splitKey.length > 1 ? splitKey[0] + '_' + (Number(splitKey[1]) + 4) : ''; // color_6 etc
                if (matchEditorColorKey) {
                    Object.keys(textsWithGlobalColors).forEach( textStyle => {
                        if (textsWithGlobalColors[textStyle] === matchEditorColorKey) {
                            cssRules += `.${textStyle} { color: ${textColorValue} !important; }\n`;
                        }
                    })
                }
            }
         });

        // Map of iframe IDs for each viewport
        const iframeIdMap = {
            desktop: 'iframe-desktop',
            tablet: 'iframe-tablet',
            mobile: 'iframe-mobile',
        };

        // Loop through all iframe IDs and apply styles to all viewports
        Object.keys(iframeIdMap).forEach(viewport => {
            const iframe = document.getElementById(iframeIdMap[viewport]);

            // If iframe is found, apply styles regardless of visibility
            if (iframe && iframe.contentDocument) {
                const iframeDoc = iframe.contentDocument;
                let styleElement = iframeDoc.getElementById('palette-styles');
                if (!styleElement) {
                    styleElement = iframeDoc.createElement('style');
                    styleElement.id = 'palette-styles';
                    iframeDoc.head.appendChild(styleElement);
                }

                styleElement.textContent = cssRules;
            }
        });
    }

    removeCssRulesFromIframes() {
        // Map of iframe IDs for each viewport
        const iframeIdMap = {
            desktop: 'iframe-desktop',
            tablet: 'iframe-tablet',
            mobile: 'iframe-mobile',
        };

        // Loop through all iframe IDs and remove styles from all viewports
        Object.keys(iframeIdMap).forEach(viewport => {
            const iframe = document.getElementById(iframeIdMap[viewport]);

            // If iframe is found, remove the style element
            if (iframe && iframe.contentDocument) {
                const iframeDoc = iframe.contentDocument;
                const styleElement = iframeDoc.getElementById('palette-styles');

                // If the style element exists, remove it
                if (styleElement) {
                    iframeDoc.head.removeChild(styleElement);
                }
            }
        });
    }
}

const colors = new Colors();

export default colors;