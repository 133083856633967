import languagesJSON from '../assets/json/languages.json'
export default {
    data() {
        return {
            filterData: [],
            filterType: 'STANDARD',
            loadingTemplates: false
        };
    },
    computed: {
        filteredTemplates() {
            let filteredList = this.templates;
            const filterKeys = Object.keys(this.filterData);
            if (filterKeys.length) {
                filteredList = filteredList.filter(template => {
                    return filterKeys.every( filterType => {
                        if (filterType === 'tags' && this.filterData[filterType].length) {
                            if (filterType === 'tags' && !template.templateHeadings) {
                                return false;
                            }
                            const category = template.templateHeadings.map(heading => heading.translationKey);
                            const intersection = this.filterData[filterType].filter(x => category.includes(x));
                            return intersection.length > 0;
                        } else if ((filterType === 'paletteReady' ||filterType === 'locale' ) && template[filterType] !== undefined && this.filterData[filterType] !== '') {
                            return this.filterData[filterType] === template[filterType];
                        } else if (filterType === 'search' && this.filterData[filterType] !== '') {
                            return ['id', 'description', 'name'].some( searchKey => {
                                if (template[searchKey] !== undefined) {
                                    const searchValue = String(template[searchKey]).toLowerCase();
                                    const filterValue = String(this.filterData[filterType]).toLowerCase();
                                    return searchValue.includes(filterValue);
                                }
                            })
                        }
                        return true;
                    })
                });
            }
            if (this.filterType.length) {
                filteredList = filteredList.filter(template => this.filterType.includes(template.type.toUpperCase()));
            }
            return filteredList;
        }
    },
    methods: {
        setFilterData(filterData) {
            if (filterData === 'AI' || filterData === 'STANDARD') {
                this.filterType = filterData;
                return;
            }
            this.filterData = filterData;
        },
        clearTemplate() {
            this.$store.dispatch('setSelectedTemplateInfo', {});
            this.$store.dispatch('setTemplateId', '');
            this.$store.dispatch('setSelectedColors', {});
        },
        setTemplatesLanguages(apiTemplates) {
            const apiTemplatesLanguages = [...new Set(Object.keys(apiTemplates).map(element => apiTemplates[element]?.locale.toUpperCase()))];
            const templatesLanguages = Object.entries(languagesJSON.languages)
            .filter(lang => apiTemplatesLanguages.includes(lang[1].value))
            .map(lang => ({
                "name": this.$t(`general.languages.`+ lang[1].value),
                "value": lang[1].value.toLowerCase()
            }));
            this.$store.dispatch('setTemplatesLanguages', templatesLanguages);
        }
    }
};