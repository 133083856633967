<template>
	<div class="loginWrapper">
		<div class="sideimage">
			<img :src="'../images/' + sidebarLoginImg" class="illustration">
		</div>
		<div class="container login-container">
			<div class="login-form col-md-4">
				<h3 class="headline h3">{{ $t("quickCreator.login.headline.enter") }}</h3>
				<h2 class="headline h2">{{ $t("quickCreator.login.headline.quickCreator") }}</h2>
				<input-text
					class="mds-input-text"
					label="quickCreator.login.username"
					index="username"
					:value="model.username"
					@change="setData" />
				<input-text
					class="mds-input-text"
					type="password"
					label="quickCreator.login.password"
					index="password"
					:value="model.password"
					@change="setData" />
				<div v-if="(!loginAuthenticated && model.username === '') || wrongCredentials"
					class="warningBox mono-size-tiny red">
					{{ errorMessage }}
				</div>

				<mv-button class="button"
					size="medium"
					text="quickCreator.login.button"
					:isLoading="loginLoading"
					@click="login" />

				<span class="link">
					<a href="#" class="link mono-size-tiny" @click.prevent="openForgotPasswordModal">
						{{ $t("quickCreator.login.forgotPassword") }}
					</a>
				</span>
			</div>
		</div>
		<div v-if="forgotPasswordModal" class="modal-overlay">
			<mv-modal
				title="quickCreator.login.forgotPasswordModal.title"
				:firstButton="false"
				:secondButton="true"
				secondButtonText="quickCreator.login.forgotPasswordModal.secondButtonText"
				@second-button-clicked="closeForgotPasswordModal"
				@modal-close-clicked="closeForgotPasswordModal">
					<div slot="mv-modal-content">
						<span class="text">{{ $t("quickCreator.login.forgotPasswordModal.text1") }}</span>
					</div>
			</mv-modal>
		</div>
		<mv-icon-sprite />
	</div>
</template>

<script>
import { getTemplates } from "@/services/api";
import { mapGetters } from 'vuex';
import axios from "axios";
import { set, merge } from "lodash";
import { loadLanguage } from '@/utils/lang';
import { $ss, $ls } from "@/plugins/localstorage";
import { MvButton, InputText, MvModal, MvIconSprite } from "@monosolutions/vue-components";
import TemplateSelectMixin from '../../mixins/TemplateSelectMixin';

export default {
	name: "LoginForm",
	components: { MvButton, InputText, MvModal, MvIconSprite },
	mixins: [ TemplateSelectMixin ],
	data: () => {
		return {
			model: {
				username: "",
				password: "",
				ticket: "",
			},
			link: "https://www.mono.site",
			loginAuthenticated: true,
			wrongCredentials: false,
			errorMessage: "",
			loginLoading: false,
			forgotPasswordModal: false,
			sidebarLoginImg: '1x-Bricklayer.png'
		};
	},
	created() {
		document.body.style.backgroundColor = "#FFF";
	},
    computed: {
        ...mapGetters({
			sessionId: 'sessionId',
        }),
    },
	methods: {
		setData(payload) {
			const objData = {};
			set(objData, payload.index, payload.data.value);
			this.model = merge({}, this.model, objData);
		},
		async login() {
			if (this.model.username !== "" && this.model.password !== "") {
				this.loginLoading = true;
				this.wrongCredentials = false;

				const body = {
					username: this.model.username,
					password: this.model.password,
				};

				const loggedin = await this.requestLogin(body);

				if ($ss.get("loginToken") !== null && loggedin) {
					this.$router.push({ name: "step", params: { step: 1 } }).catch(() => { });
					const apiTemplates = await getTemplates(this.sessionId);
					this.$store.dispatch('setTemplates', apiTemplates);
					this.setTemplatesLanguages(apiTemplates);
				}

			} else {
				this.loginAuthenticated = false;
				this.errorMessage = this.$t("quickCreator.login.errors.missingCredentials");
			}
		},
		async requestLogin(body) {
			const quickCreatorBaseUrl = process.env.VUE_APP_QC_BASE_URL;

			try {
				const response = await axios.post(`${quickCreatorBaseUrl}/login`, body,
					{
						headers: {
							"Content-Type": "application/json",
						},
					});

				if (response.status === 200 && response.data) {
					const settings = response.data;
					this.$store.dispatch("setSessionId", settings.sessionId);
					this.$store.dispatch("setDefaultCountry", settings.resellerDefaultCountry);
					this.$store.dispatch("setSettings", settings.quickCreator);
					this.$store.dispatch("setDefaultLanguage", settings.resellerDefaultLanguage);
					this.$store.dispatch('updateStepData', settings.quickCreator.modules);
					this.$store.dispatch('setIsAIEnabled', settings.aiAvailable);
					$ss.set("loginToken", settings.sessionId);
					$ss.set("defaultCountry", settings.resellerDefaultCountry);
					$ss.set("isAIEnabled", settings.aiAvailable);
					await this.clearStorageIfResellerChange(settings.resellerId);
					$ls.set("templatesReseller", settings.resellerId);
					await loadLanguage(settings.resellerQuickCreatorLanguage, settings.resellerId);
					this.loginLoading = false;
					return true;
				}
			} catch (error) {
				this.wrongCredentials = true;
				this.loginAuthenticated = false;
				this.loginLoading = false;
				this.errorMessage = this.$t("quickCreator.login.errors.credentials");
				console.error("QUICK CREATOR LOGIN ERROR", error);
				throw error;
			}
		},
		closeForgotPasswordModal() {
			this.forgotPasswordModal = false;
		},
		openForgotPasswordModal() {
			this.forgotPasswordModal = true;
		},
		async clearStorageIfResellerChange(resellerId) {
			const storedReseller = $ls.get("templatesReseller");
			if(storedReseller !== resellerId){
				$ls.remove("templateData");
				await this.$store.dispatch('clearLargeTemplateData');
			}
		}
	},
};
</script>
